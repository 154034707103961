.list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  width: 100%;
  margin-top: 30px;
  margin-right: -15px;
  margin-bottom: 15px
}
.list .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 470px;
  margin-bottom: 30px;
  padding: 20px;
  background: var(--white);
  -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.15);
  box-shadow: 0 15px 30px rgba(0,0,0,.15);
  border-radius: 5px;
  border-top: 8px solid var(--yellow)
}
.list .card h2 {
  display: block;
  text-align: center;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--yellow)
}
.list .card img {
  display: block;
  margin: 20px auto;
  min-height: 215px
}
.list .card h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 45px;
  font-family: Circular;
  font-style: normal;
  font-weight: 450;
  font-size: 1rem;
  line-height: 23px;
  text-align: center;
  color: var(--grey)
}
.list .card p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 45px;
  margin: 0 auto;
  font-family: Circular;
  font-style: normal;
  font-weight: 450;
  font-size: .7rem;
  line-height: normal;
  text-align: center;
  color: var(--grey)
}
.list .card .card_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 44px;
  margin: auto;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--white);
  background: var(--yellow);
  border-radius: 100px;
  border: 0;
  cursor: pointer
}
@media (min-width:1200px) {
  .list {
    margin-top: 50px;
    margin-right: -15px;
    margin-bottom: 70px
  }
  .list .card {
    width: calc((100% / 3) - 30px);
    margin: 30px 30px 0 0
  }
}
@media (min-width:1800px) {
  .list .card {
    width: calc((100% / 4) - 30px)
  }
}