.chat-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
}

.chat-user-message-container {
  width: 100%;
  height: 50px;
  background-color: #f5f7f9;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 10px;
}
.chat-user-message {
  display: flex;
}

.chat-message-avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.chat-user-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.chat-user-name {
  font-size: 14px;
  font-weight: 400;
  color: #1d2129;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-list {
  width: 100%;
    background-color: #fff;
  height: calc(100% - 120px);
  border: 2px solid #DEE4E9;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  overflow-y: scroll;
}

.chat-list-footer {
  height: 77px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #DEE4E9;
  background-color: #fff;
  border-radius: 14px;
}

.chat-list-footer-button {
  width: 85%;
  height: 45px;
  background-color: var(--main-color);
  border-radius: 8px;
  border: none;
  color: #1C1B1F;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.chat-list-footer-button svg {
  margin-right: 10px;
}
.chat-list-footer-button svg path {
  transition: all 0.2s ease-in-out;
}

.chat-list-footer-button:hover {
  background-color: #FFF;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.chat-list-footer-button:hover svg path {
  fill: var(--main-color);
}

.chat-user-message-button {
  border: none;
  border-radius: 8px;
  width: 100px;
  height: 26px;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: #1C1B1F;
  font-size: 10px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.chat-user-message-button:hover {
  background-color: #FFF;
  color: var(--main-color);
}

.chat-user-typing {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #3B3B3B;
  font-weight: 400;
}

.unread_message {
  font-size: 12px;
  font-weight: 600;
  color: #1C1B1F;
}

.read_message {
  font-size: 12px;
  font-weight: 400;
  color: #828284;
}

.chat-user-typing::after {
  content: "...";
  position: absolute;
  top: 0;
  right: -22px;
  display: inline-block;
  width: 20px;
  height: 7px;
  border-radius: 10px;
  animation: typing 1s infinite;
}

@keyframes typing {
  0% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}


@media (max-width: 375px) {
  .chat-list-footer-button {
    height: 35px;
  }
  .chat-user-name {
   max-width: 85px;
  }
}
