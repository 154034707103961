.btn_cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 65px;
  padding: 20px;
  margin: 30px auto;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--white);
  background: var(--dark-grey);
  border: 0;
  border-radius: 100px
}
@media (min-width:1200px) {
  .btn_cta {
    width: 652px;
    margin: 70px auto
  }

  a:hover {
    color: white;
    outline: 0;
    text-decoration:none;
  }
}