.private-chat__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.private-chat__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 60px;
    border: 2px solid #DEE4E9;
    background-color: #f5f5f5;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.1);
}

.private-chat__header__info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.private-chat__header__info__btn___back {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}

.private-chat__header__info__user {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.private-chat__header__info__name {
    font-size: 16px;
    font-weight: 500;
    color: #3B3B3B;
}

.private-chat__header__info__status {
    font-size: 10px;
    font-weight: 400;
    color: #3B3B3B;
}

.private-chat__header__actions__btn___close {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.private-chat__header__actions__btn___close svg {
    transform: rotate(-90deg);
}

.private-chat__messages {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px - 15%);
    overflow-y: auto;
    border: 2px solid #DEE4E9;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    background-color: #fff;
    scroll-behavior: smooth;
}

.private-chat__messages__message {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.private-chat__messages__message__avatar {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
}
.private-chat__messages__message__avatar img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #FFF;
}

.my-avatar {
    margin-left: 10px;
}

.private-chat__msg___sent .private-chat__messages__message {
    justify-content: flex-end;
}

.private-chat__messages__container.private-chat__msg___received {
    background-color: #FFF9ED;
}

.private-chat__send__message {
    display: flex;
    height: 15%;
    justify-content: center;
    background-color: #FFF;
    border: 2px solid #DEE4E9;
    display: flex;
    align-items: center;
    border-radius: 14px;
    padding: 19px 16px;
    justify-content: space-between;

}
.private-chat__messages__message__date {
    font-size: 10px;
    font-weight: 400;
    color: #3B3B3B;
    margin-top: 5px;
    align-self: flex-end;
}

.private-chat__messages__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 10px;
}

.private-chat__messages__message__content {
    padding-left: 10px;
    max-width: 80%;
}

.private-chat__messages__message__content__name {
    font-size: 14px;
    font-weight: 500;
    color: #1C1B1F;
    text-align: end;
}

.private-chat__messages__message__content__text {
    font-size: 12px;
    font-weight: 400;
    color: #1C1B1F;
    margin-top: 5px;
}

.private-chat__send__message__input {
    display: flex;
    position: relative;
    width: calc(100% - 87px);
    height: 38px;
    border: 1px solid var(--main-color);
    border-radius: 6px;
    padding-right: 16px;
    padding-left: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #3B3B3B;
    outline: none;
    box-sizing: border-box;
}

.EmojiPickerReact {
    position: absolute !important;
    bottom: 60px;
    right: 17px;
}
.private-chat__send__message__input__emoji {
    all: unset;
    cursor: pointer;
    position: absolute;
    margin-left: 10px;
}
.private-chat__send__message__btn {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 80px;
    border-radius: 8px;
    background-color: var(--main-color);
    color: #1C1B1F;
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
}

.private-chat__send__message__btn:hover {
    background-color: #FFF;
    color: var(--main-color);
}

.private-chat__header__info__typing {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: #3B3B3B;
  font-weight: 400;
}
.private-chat__header__info__typing::after {
  content: "...";
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 7px;
  border-radius: 10px;
  animation: typing 1s infinite;
}


@media (max-width: 468px) {
  .private-chat__send__message {
    padding: 0px 10px;
  }

  .EmojiPickerReact {
    bottom: 75px;
    right: 13px;
  }

}

