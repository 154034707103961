.new-chat__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 2px solid #DEE4E9;
    border-radius: 14px;
}



.contact-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 147px);
    overflow-x: scroll;
}
.contact-list__item__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.contact-list__item {
    width: 100%;
    height: 50px;
    padding: 12px;
    display: flex;
    margin-bottom: 10px;
    border-radius: 0px 8px 8px 0px;
    align-items: center;
    background-color: #F5F7F9;
}
.contact-list__item__avatar {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
}
.contact-list__item__info__name {
    font-size: 14px;
    font-weight: 500;
    color: #3B3B3B;
}
.contact-list__item__statuses {
    display: flex;
    flex-direction: row;
}
.contact-list__item__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 10px;
    font-weight: 400;
}
.contact-list__item__info__status {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}
.contact-list__item__info__status__indicator {
    width: 5px;
    height: 5px;
    display: flex;
    border-radius: 50%;
    margin-right: 5px;
}

.contact-list__item__info__status__indicator--online {
    background-color: #4CAF50;
}

.contact-list__item__info__status__indicator--offline {
    background-color: #9E9E9E;
}

.contact-list__item__info__status__indicator--away {
    background-color: #FFC107;
}

.contact-list__item__info__role {
    font-size: 10px;
    font-weight: 400;
    color: #3B3B3B;
    display: flex;
    align-items: center;
}

.contact-list__item__info__role svg {
    margin-bottom: 1.5px;
    margin-right: 5px;
}

.contact-list__item__info__message-btn {
    width: 100px;
    height: 30px;
    border-radius: 8px;
    border: none;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    font-size: 10px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.contact-list__item__info__message-btn:hover {
    background-color: #FFF;
    color: var(--main-color);
    border: 1px solid var(--main-color);
}