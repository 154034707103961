.footerClubeBeneficios {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-top: 15px;
  background: #f4f2f2;
  border-radius: 50px 50px 0 0;
  font-family: Circular;
  font-style: normal;
  font-weight: 450;
  font-size: .5rem;
  line-height: normal;
  color: var(--grey)
}
.footerClubeBeneficios a {
  display: block;
  margin: 0 10px;
  text-decoration: none;
  color: var(--grey)
}
.footerClubeBeneficios .copy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  padding: 15px 0;
  background: #ebebeb
}
.footerClubeBeneficios .copy a {
  display: contents
}
@media (min-width:1200px) {
  .footerClubeBeneficios {
    padding-top: 30px;
    font-size: 14px;
    line-height: 18px
  }
  .footerClubeBeneficios .copy {
    margin-top: 30px;
    padding: 20px 0
  }
}