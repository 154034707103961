@font-face {
  font-family: CircularStdBook;
  src: url(../font/CircularStdBook.otf);
}

@font-face {
  font-family: Arvo;
  src: url(../font/Arvo-Regular.ttf);
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: CircularStdBook;
  cursor: default;
}
form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
img {
  width: 100%;
}
.d_block {
  display: block;
}
.w_sm_80 {
  width: 80%;
}
.bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer {
  background-size: cover;
  background-repeat: no-repeat;
}
.bg_header {
  background-image: url(/img/sign/capa.png);
  min-height: 50vh;
  background-position: bottom left;
}
.bg_branco {
  background-color: #fff;
}
.bg_branco_2 {
  background-color: #fff;
  min-height: 150vh;
}
.bg_seletivas {
  background-image: url(/img/sign/seletivas.jpg);
  background-color: #fff;
  border-radius: 0 0 50px 50px;
  background-position: top;
}
.onda::before {
  content: url(/img/sign/onda_sup.svg);
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  margin-top: calc(-23vw + 10px);
}
.onda::after {
  content: url(/img/sign/onda_inf.svg);
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  margin-top: -1px;
}
.bg_footer {
  background: #31393d;
  border-radius: 40px 40px 0 0;
}
.bg_modal {
  background-image: url(/img/sign/modal.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px 0 0 10px;
  background-position: center;
}
.header {
  margin-top: 30px;
  margin-left: 15px;
}
.logo_header {
  width: 140px;
}
.container_sociais {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px 0 0 20px;
  width: 40px;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: calc(50% - 125px);
  height: 120px;
  right: 0;
  z-index: 10;
  padding: 10px 0;
}
.content_sociais {
  width: 34%;
  display: flex;
}
.content_sociais_2 {
  width: 48%;
  display: flex;
}
.img_sociais {
  width: 100%;
}
.jogador {
  margin-top: -5px;
  background: #fff;
}
.container_jogador {
  display: grid;
  grid-template-areas: 'title' 'img' 'descript';
  padding: 30px 15px;
}
.content_jogador {
  grid-area: img;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
}
.title_jogador {
  font-family: Arvo;
  grid-area: title;
  text-align: center;
  font-size: 1.2rem;
  color: #31393d;
  font-weight: 700;
  margin-bottom: 35px;
}
.text_jogador {
  grid-area: descript;
  color: #687171;
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
}
.img_jogador {
  width: 50%;
  height: auto;
  z-index: 1;
}
.container_seletivas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
  padding: 40px 15px;
}
.content_seletivas {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 67%;
}
.title_seletivas {
  font-family: Arvo;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 35px;
}
.text_seletivas {
  color: #e5e5e5;
  font-weight: 500;
  font-size: 0.7rem;
}
.content_seletivas_meio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -45px;
  background: #fff;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  z-index: 9;
  height: 90px;
  padding: 0 40px;
}
.content_seletivas_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  z-index: 10;
  padding: 0 40px;
}
.container_vantagens {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-bottom: 10px;
}
.content_vantagens {
  background: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
}
.content_vantagens:last-child {
  margin-right: 0;
}
.img_vantagens {
  width: 30%;
}
.title_vantagens {
  display: flex;
  justify-content: center;
  font-family: Arvo;
  color: #31393d;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 1.2rem;
  position: relative;
  width: 100%;
  top: -30px;
}
.text_vantagens {
  height: auto;
  font-size: 0.8rem;
  color: #687171;
  text-align: center;
  font-weight: 500;
  position: absolute;
  width: 61%;
  right: calc(100% / 25);
  margin-top: 10px;
}
.container_camisa {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80px;
  padding: 15px 35px;
  background-image: url(/img/sign/camisa.jpg);
  background-size: cover;
  background-position: center;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 15px;
}
.content_camisa {
  display: flex;
  align-items: center;
  width: 50%;
}
.title_camisa {
  font-family: Arvo;
  font-size: 0.8rem;
  color: #fff;
  font-weight: 700;
}
.text_camisa {
  font-family: Arvo;
  color: #fff;
  font-weight: 700;
  font-size: 0.7rem;
}
.hr_camisa {
  background: #fff;
  border-radius: 50px;
  box-sizing: border-box;
  border-left: #fff;
}
.linha_vertical {
  border-right: #fff 2px solid;
  width: 5%;
  position: absolute;
  right: -1px;
}
.img_camisa {
  width: 58px;
  margin-right: -18px;
  padding-left: 18px;
  margin-left: 18px;
  border-left: 2px solid #fff;
  position: relative;
  left: -30px;
}
.container_faq {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}
.content_faq {
  background: #fff;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  margin-bottom: 80px;
}
.content_faq_2 {
  display: none;
  width: 100%;
  flex-wrap: wrap;
}
.content_faq_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_faq_box_2 {
  width: 100%;
  display: none;
  height: auto;
  margin: 25px 0;
}
.content_faq_box_mobile {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 0.7rem;
  padding: 5px 15px;
}
.title_faq {
  font-family: Arvo;
  color: #31393d;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 20px 10px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.text_faq {
  font-weight: 700;
  width: 100%;
  font-size: 0.7rem;
  text-align: left;
}
.text_faq_2 {
  font-weight: 500;
  font-size: 0.9rem;
}
.text_link {
  font-weight: bold;
  font-size: 0.9rem;
  color: #000;
  cursor: pointer;
}
.box_faq {
  position: relative;
  background: #fff;
  color: #31393d;
  display: flex;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 100%;
  min-height: 150px;
  padding: 15px;
  border: none;
  margin-bottom: 13px;
  transition: all 0.2s linear;
}
.collapsed {
  background: #31393d;
  color: #fff;
}
.box_faq_click {
  background: #fff;
  color: #31393d;
  display: flex;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 100%;
  padding: 15px;
  border: none;
  margin: 7px 0;
}
.box_faq2 {
  background: #31393d;
  color: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 100%;
  padding: 15px;
  border: none;
  margin: 7px 0;
}
.container_assinar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  width: 100%;
  color: #687171;
  background-image: url(/img/sign/assinar.png);
  background-position: center;
  border-radius: 10px;
  margin-bottom: 30px;
  min-height: 260px;
}
.btn_assinar {
  font-family: Arvo;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56%;
  height: 40px;
  background: #fff;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 200px;
  border: none;
  font-size: 0.8rem;
  font-weight: 700;
  color: #31393d;
  cursor: pointer;
}
.text_assinar {
  font-family: Arvo;
  width: 65%;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0;
}
.text_assinar_2 {
  width: 100%;
  color: #fff;
  font-weight: 700;
  align-items: center;
  font-size: 0.8rem;
  text-align: center;
}
.container_footer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.container_footer_2 {
  display: flex;
  justify-content: center;
  text-align: center;
  width: calc(100% + 30px);
  margin: 10px -15px 0;
  padding: 10px;
  background: #2d3336;
  color: #687171;
  font-size: 0.7rem;
  font-weight: 500;
}
.content_footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #fff;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  position: relative;
  z-index: 10;
  padding: 0 15px;
}
.img_footer {
  width: 136px;
}
.img_footer_enviar {
  width: 100%;
  display: flex;
  margin: auto;
}
.title_footer {
  font-family: Arvo;
  color: #fff;
  font-weight: 700;
  font-size: 0.7rem;
  width: 100%;
  margin: 10px auto 25px;
}
.text_call_to_action {
  text-align: center;
  width: 100%;
  margin: 5px;
  font-family: Arvo;
  font-size: 0.7rem;
  font-weight: 700;
  color: #31393d;
  padding: 0;
}
.call_to_action {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.text_footer_2 {
  color: #687171;
  font-weight: 500;
  font-size: 0.8rem;
}
.input_footer {
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  font-size: 0.5rem;
  width: 70%;
  height: 25px;
  padding: 0 10px;
}
.input_footer::placeholder {
  color: #838b89;
  font-size: 0.5rem;
}
.btn_footer {
  background: #fff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  width: 25px;
  margin-left: 10px;
  cursor: pointer;
}
.modal {
  display: none;
  position: fixed;
  z-index: 21;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(22, 22, 22, 0.8);
  align-items: center;
  justify-content: center;
}
.container_modal {
  display: flex;
  position: relative;
  width: 90%;
  min-height: 60%;
  max-width: 1100px;
  align-items: center;
  background: #fff;
  border-radius: 10px;
}
.container_modal_2 {
  display: flex;
  position: relative;
  width: 90%;
  height: 60%;
  max-width: 1100px;
  align-items: center;
  background: #fff;
  border-radius: 10px;
}
.container_modal_3 {
  display: flex;
  position: relative;
  width: 90%;
  height: 60%;
  max-width: 1100px;
  align-items: center;
  background: #fff;
  border-radius: 10px;
}
.content_modal {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin: auto;
}
.title_modal {
  width: 100%;
  font-weight: 700;
  color: #000;
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: Arvo;
}
.text_modal {
  width: 100%;
  color: #687171;
  font-weight: 700;
  font-size: 1rem;
}
.text_modal_2 {
  width: 100%;
  color: #151515;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  margin: 10px 0;
}
.btn_modal {
  background: #687171;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 200px;
  border: none;
  color: #fff;
  height: 40px;
  width: 100%;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}
.link_btn_modal {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: auto;
}
.content_modal_plano {
  display: flex;
  position: relative;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: auto;
}
.content_modal_plano_2 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 220px;
  margin: 0 auto 10px auto;
  background: linear-gradient(180deg, #313131 0%, #6b757d 100%);
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.content_modal_plano_2:hover {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 220px;
  margin: 10px auto 20px auto;
  background: linear-gradient(180deg, #151515 0%, #444545 100%);
  border-radius: 30px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.25);
  transition: all 200ms ease-out;
  cursor: pointer;
}
.content_modal_plano_2::after {
  content: '';
  position: absolute;
  top: -30%;
  right: -13%;
  bottom: -29%;
  left: -95%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(150, 150, 150, 0.5) 50%, rgba(229, 172, 142, 0));
  transform: rotateZ(45deg) translate(0em, 7.5em);
  cursor: pointer;
}

.content_modal_plano_2:hover::after,
.content_modal_plano_2:focus::after {
  animation: sheen 1.5s forwards;
}

@keyframes sheen {
  100% {
    transform: rotateZ(45deg) translate(1em, -17em);
  }
}

.content_modal_plano_3 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 90%;
  margin: auto;
}
.content_modal_plano_4 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 220px;
  margin: 10px auto 20px auto;
  background: linear-gradient(180deg, #151515 0%, #444545 100%);
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.content_modal_plano_4::after {
  content: '';
  position: absolute;
  top: -30%;
  right: -13%;
  bottom: -29%;
  left: -95%;
  background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(150, 150, 150, 0.5) 50%, rgba(229, 172, 142, 0));
  transform: rotateZ(45deg) translate(0em, 7.5em);
  cursor: pointer;
}

.content_modal_plano_4:hover::after,
.content_modal_plano_4:focus::after {
  animation: sheen 1.5s forwards;
}
.content_modal_plano_4:hover {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 220px;
  margin: 10px auto 20px auto;
  background: linear-gradient(180deg, #151515 0%, #444545 100%);
  border-radius: 30px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.25);
  transition: all 200ms ease-out;
  cursor: pointer;
}
.title_modal_plano {
  font-family: Arvo;
  width: 100%;
  font-weight: 700;
  color: #000;
  font-size: 1.5rem;
  margin: 20px 0;
  text-align: center;
  padding: 0 40px;
}
.title_modal_plano_2 {
  font-family: Arvo;
  width: 100%;
  font-weight: 700;
  color: #cccccc;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 25px;
}
.text_modal_plano {
  width: 100%;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 25px;
  font-family: Arvo;
}
.text_modal_plano_2 {
  width: 100%;
  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 35px;
  font-family: CircularStdBook;
}
.btn_modal_plano {
  font-family: Arvo;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 200px;
  border: none;
  color: #444545;
  height: 50px;
  width: 50%;
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
}
.close_modal {
  position: absolute;
  color: #31393d;
  font-size: 1.6rem;
  font-weight: 700;
  top: 0;
  right: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
}
.swiper-container {
  display: flex;
  width: 100%;
  min-height: 40vh;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}
.swiper-slide {
  text-align: center;
  font-size: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* -webkit-align-items: center;
  align-items: center; */
}
.content_faq_slid {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px;
  margin: 50px 10px 30px 10px;
}
.list_quest {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 0 auto;
}
.quest_text {
  display: flex;
  width: 60%;
}

.paginas {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
}

.img_bola {
  position: absolute;
  width: 26%;
  right: -6px;
  bottom: 0;
  height: 42%;
}
.meio_circulo {
  position: absolute;
  width: 9%;
  top: 35px;
  left: 0;
}

@media (max-width: 375px) {
  .box_faq {
    min-height: 180px;
  }

  .img_camisa {
    width: 45px;
    margin-right: -22px;
    padding-left: 15px;
    margin-left: 26px;
  }
  .title_vantagens {
    top: -23px;
  }
  .title_camisa {
    font-size: 0.7rem;
  }
  .text_camisa {
    font-size: 0.6rem;
  }

  .title_faq {
    margin: 20px;
  }

  .input_footer {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .swiper-container {
    min-height: 70vh;
    margin-bottom: 8vh;
  }
  .bg_header {
    min-height: 130vh;
  }
  .bg_footer {
    border-radius: 100px 100px 0 0;
  }
  .onda::before {
    margin-top: calc(-21vw + -1px);
  }
  .logo_header {
    /* width: 50%; */
    width: 230px;
  }
  .container_sociais {
    height: 140px;
    width: 50px;
  }
  .container_jogador {
    grid-template-areas: 'img title' 'img descript';
    column-gap: 100px;
    padding: 80px 15px;
  }
  .content_jogador {
    margin-bottom: 10px;
  }
  .img_jogador {
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .title_jogador {
    text-align: left;
    font-size: 2.5rem;
  }
  .text_jogador {
    font-size: 1.5rem;
    text-align: left;
  }
  .container_seletivas {
    margin-top: 14vw;
    padding: 80px 0;
  }
  .content_seletivas {
    width: 49%;
  }
  .title_seletivas {
    font-size: 2.5rem;
    margin-bottom: 35px;
  }
  .text_seletivas {
    font-size: 1.5rem;
  }
  .content_seletivas_meio {
    flex-direction: row;
    height: 155px;
    padding: 0 50px;
    margin-bottom: -77.5px;
    width: 100%;
  }
  .text_call_to_action {
    text-align: center;
    width: 50%;
    font-size: 1.4rem;
    margin: 0;
  }
  .call_to_action {
    width: 49%;
    justify-content: flex-end;
  }
  .container_footer_2 {
    font-size: 0.8rem;
    margin: 20px -15px 0;
  }
  .input_footer {
    font-size: 1.4rem;
    height: 78px;
    padding: 0 25px;
    width: 80%;
  }
  .input_footer::placeholder {
    font-size: 1.2rem;
  }
  .btn_footer {
    width: 78px;
  }
  .img_footer_enviar {
    width: 60%;
  }
  .container_vantagens {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 157.5px;
    margin-bottom: 80px;
  }
  .content_vantagens {
    width: calc((100% / 3) - 45px);
    margin-right: 45px;
    flex-direction: column;
    border-radius: 200px 200px 50px 50px;
    height: 100%;
    padding: 30px 30px 28px 30px;
    margin-bottom: 0;
  }
  .img_vantagens {
    width: 60%;
  }
  .content_faq {
    padding: 30px;
  }
  .content_faq_2 {
    display: flex;
  }
  .title_vantagens {
    margin: 28px 0 26px 0;
    font-size: 2rem;
    position: initial;
  }
  .text_vantagens {
    height: auto;
    font-size: 1.2rem;
    position: inherit;
    width: 100%;
    margin-top: 0;
  }
  .title_camisa {
    font-size: 2.3rem;
    width: 76%;
    margin-left: auto;
    margin-right: 50px;
  }
  .text_camisa {
    font-size: 1.8rem;
  }
  .img_camisa {
    width: 163px;
    margin-right: 17px;
    padding-left: 80px;
    margin-left: 0;
  }
  .container_camisa {
    min-height: 220px;
    padding: 50px;
    margin-bottom: 80px;
    padding: 15px 55px;
    border-radius: 50px;
  }
  .content_faq_slid {
    margin: 76px;
  }
  .container_assinar {
    padding: 80px;
    margin-bottom: 80px;
    border-radius: 50px;
    min-height: 10px;
  }
  .text_assinar_2 {
    display: flex;
    font-size: 1.5rem;
    margin-bottom: 60px;
  }
  .text_assinar {
    width: 47%;
    font-size: 2rem;
    margin-bottom: 40px;
  }
  .btn_assinar {
    width: 37%;
    height: 80px;
    font-size: 1.5rem;
  }
  .content_seletivas_footer {
    flex-direction: row;
    height: 140px;
    margin-bottom: 50px;
    padding: 0 50px;
  }
  .title_footer {
    width: auto;
    font-size: 1.8rem;
    margin: 15px auto 40px;
  }
  .text_footer_2 {
    font-size: 1.3rem;
  }
  .img_footer {
    width: 22%;
  }
  .container_footer {
    margin-top: 50px;
  }
  .box_faq::before {
    content: '-';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 3px solid #31393d;
    color: #31393d;
    right: 30px;
    font-size: 2rem;
  }
  .box_faq {
    margin: 10px 0;
    padding: 15px 30px;
    min-height: 170px;
  }
  .text_faq {
    font-size: 1.2rem;
    font-weight: 700;
    width: 80%;
  }
  .text_link {
    font-weight: bold;
    font-size: 1.5rem;
    color: #000;
    cursor: pointer;
  }
  .title_faq {
    font-size: 2rem;
    margin: 30px;
  }
  .content_faq_box_mobile {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 5px 30px;
  }
  .container_modal {
    width: 60%;
  }
  .content_modal {
    align-items: center;
    width: 60%;
  }
  .title_modal {
    font-size: 2.5rem;
    margin: 0 0 15px 0;
  }
  .text_modal {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .text_modal_2 {
    font-size: 1.5rem;
    margin: 0 0 15px 0;
  }
  .btn_modal {
    height: 50px;
    width: 60%;
    font-size: 1.3rem;
  }
  .container_modal_2 {
    width: 60%;
  }
  .container_modal_3 {
    height: 75%;
    width: 60%;
  }
  .content_modal_plano {
    flex-direction: row;
    width: 90%;
  }
  .content_modal_plano_2 {
    height: 300px;
    margin: 50px auto;
  }
  .content_modal_plano_2:hover {
    height: 300px;
    margin: 50px auto;
  }
  .content_modal_plano_4 {
    height: 300px;
    margin: 50px auto;
  }
  .content_modal_plano_4:hover {
    height: 300px;
    margin: 50px auto;
  }
  .title_modal_plano {
    font-size: 2rem;
    margin-bottom: 40px;
    margin-top: 40px;
    padding: 0;
  }
  .title_modal_plano_2 {
    font-size: 2rem;
    margin-bottom: 35px;
  }
  .text_modal_plano {
    font-size: 2rem;
    margin-bottom: 35px;
  }
  .text_modal_plano_2 {
    font-size: 1.5rem;
  }
  .btn_modal_plano {
    font-size: 1rem;
  }
  .paginas {
    height: 13vh;
  }

  .flex-1 {
    flex: 1;
  }
}
