.chat-list-header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  border: 2px solid #DEE4E9;
    background-color: #fff;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.chat-new__message {
    font-size: 10px;
    color: #3B3B3B;
    padding-left: 10px;
}

.message_box {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.new-messages__number {
    font-size: 8px;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    background-color: #FF5F5F;
    border-radius: 50%;
    padding: 5px 7px;
    margin-left: 3px;
}

.new-conversation {
    font-size: 16px;
    font-weight: 500;
}

.chat-header__left {
    display: flex;
    align-items: center;
}
.chat-header__left__back {
    all: unset;
    cursor: pointer;
}
.chat-header__right {
    all: unset;
    cursor: pointer;
    transform: rotate(-90deg);
}
.chat-my__status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.chat-my__status__dot {
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 3px;
}

.chat-my__status__text {
    font-size: 10px;
    font-weight: 400;
    color: #3B3B3B;
}
.chat-my__status__text::first-letter {
    text-transform: uppercase;
}

.online {
    background-color: #00C48C;
}

.offline {
    background-color: #FF5F5F;
}

.away {
    background-color: #FFC700;
}

