@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap);

.whatsapp-title {
    font-style: normal;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25rem;
    line-height: normal;
    color: black;
    border-radius: 10px;
    border-color: black;
    border-width: 0.2px;
    border-style: solid;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    margin-bottom: 30px;
    margin-top: 20px;
    padding: 5px;
  }


.whatsapp-title-contrato {
  font-style: normal;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.45rem;
  text-transform: uppercase;
  line-height: normal;
  color: black;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 5px;
}

.whatsapp-title-img{
  float: left;
  padding-left: 50px;
  padding-right: 3px ;
  text-align: center;
}

.whatsapp-rodape{
    position: absolute;
    bottom: 0;
    padding-bottom: 15px;
    width: 92%;
}


.button_whatsapp{
  width: 20px;
  height: 40px;
  font-size: 13px;
  margin: 5px;
}