.sub_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 15px;
  background: var(--white);
  -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.15);
  box-shadow: 0 15px 30px rgba(0,0,0,.15)
}
.sub_header .user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}
.sub_header .user img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover
}
.sub_header .user span {
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: normal;
  text-transform: uppercase;
  color: var(--dark-grey)
}
.sub_header nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}
.sub_header nav span {
  width: 100%;
  margin: 0 auto 20px;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  color: var(--yellow)
}
.sub_header nav a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: .5rem;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  background-color: var(--white);
  color: var(--grey);
  border: 1px solid var(--grey);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  cursor: pointer
}
.sub_header nav a:hover {
  background-color: var(--grey);
  color: var(--white)
}
.sub_header nav .active {
  background-color: var(--grey);
  color: var(--white)
}
@media (min-width:420px) {
  .sub_header nav a {
    font-size: 1rem
  }
}
@media (min-width:1200px) {
  .sub_header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 15px 10%
  }
  .sub_header .user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }
  .sub_header .user img {
    width: 110px;
    height: 110px;
    margin-right: 30px
  }
  .sub_header .user span {
    font-size: 25px;
    line-height: 31px
  }
  .sub_header nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }
  .sub_header nav span {
    width: auto;
    margin: 0;
    font-size: 25px;
    line-height: 31px
  }
  .sub_header nav a {
    padding: 13px;
    margin-left: 15px;
    font-size: 14px;
    line-height: 17px
  }
}