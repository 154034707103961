.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 30px 15px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.15);
  box-shadow: 0 15px 30px rgba(0,0,0,.15)
}
.header .logo {
  max-width: 50vw
}
.header .menu {
  display: block
}
.header .nav {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--white)
}
.header .nav .close {
  position: absolute;
  top: 15px;
  right: 15px
}
.header .nav a {
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--dark-grey);
  margin: 10px auto
}
.header .nav .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 141px;
  height: 42px;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: var(--dark-grey);
  background: var(--yellow);
  border: 0;
  border-radius: 100px;
  cursor: pointer
}
.header .nav .btn:focus {
  outline: 0
}
@media (min-width:1200px) {
  .header {
    padding: 60px 10%
  }
  .header .logo {
    max-width: 15vw
  }
  .header .menu {
    display: none
  }
  .header .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    width: auto;
    height: auto;
  }
  .header .nav .close {
    display: none
  }
  .header .nav a {
    margin-right: 30px
  }
}