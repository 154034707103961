.form_periodo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  width: 100%;
  margin-top: 30px
}
.form_periodo label {
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  color: var(--grey)
}
.form_periodo input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 15px auto;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border: 1px solid var(--grey);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Circular;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--grey)
}
.form_periodo button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 138px;
  height: 42px;
  background: var(--yellow);
  border: 0;
  border-radius: 100px;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--dark-grey);
  cursor: pointer
}
@media (min-width:1200px) {
  .form_periodo {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 70px
  }
  .form_periodo label {
    font-size: 14px;
    line-height: 17px
  }
  .form_periodo input {
    margin: 0 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 18px;
    line-height: 23px
  }
  .form_periodo button {
    width: 138px;
    height: 42px;
    font-size: 14px;
    line-height: 17px
  }
}
.card_resgatados {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
  background: var(--white);
  -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.15);
  box-shadow: 0 15px 30px rgba(0,0,0,.15);
  border-radius: 5px
}
.card_resgatados .card_header {
  width: 100%;
  padding: 20px;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: var(--dark-grey);
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.1);
  box-shadow: 0 1px 2px rgba(0,0,0,.1)
}
.card_resgatados .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 30px 15px;
  font-family: Circular;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: var(--grey);
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.1);
  box-shadow: 0 1px 2px rgba(0,0,0,.1)
}
.card_resgatados .item span {
  display: block;
  margin-bottom: 30px;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
  color: var(--yellow)
}
@media (min-width:1200px) {
  .card_resgatados {
    margin-bottom: 70px
  }
}