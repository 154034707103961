@import url('https://fonts.cdnfonts.com/css/fivo-sans');


.chat-widget {
  all: unset;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 63px;
  height: 63px;
  z-index: 9999;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 6px 20px 2px;
  margin-bottom: 30px;
  margin-right: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 2px solid #DEE4E9;
}

.chat-container {
  position: fixed;
  bottom: 75px;
  right: 0;
  width: 400px;
  height: 500px;
  z-index: 9999;
  border-width: 2px;
  border-radius: 14px;
  margin-bottom: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  font-family: 'Fivo Sans', sans-serif;
}

@media (max-width: 468px) {
  .chat-container {
    width: 92%;
    height: 78%;
    bottom: 62px;
    left: 16px;
  }
  .chat-widget {
    margin-bottom: 15px;
    margin-right: 15px;
  }
}


.chat-search__container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px 18px 16px;
}

.chat-search__input {
   font-family: 'Fivo Sans', sans-serif;
    width: 70%;
    height: 37px;
    border: 1px solid var(--main-color);
    outline: none;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    color: #1d2129;
    background-color: #f5f7f9;
    border-radius: 8px;
    margin-right: 5px;
  }

.chat-search__btn {
    width: 30%;
    height: 37px;
    background-color: var(--main-color);
    border-radius: 8px;
    border: none;
    color: #1C1B1F;
    border: 1px solid var(--main-color);
    font-family: 'Fivo Sans', sans-serif;
    transition: all 0.2s ease-in-out;
  }
.chat-search__btn:hover {
    background-color: #FFF;
    color: var(--main-color);
    border: 1px solid var(--main-color);
}

.chat-messages {
  width: 100%;
  height: calc(100% - 120px);
  background-color: #fff;
  overflow-y: scroll;
  padding: 20px;
}

.chat-user-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-user-message-content {
  font-size: 14px;
  font-weight: 400;
  color: #1d2129;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2px; 
  max-width: 145px;
}

.chat-user-status-online {
  background-color: #4caf50;
}

.chat-user-status-offline {
  background-color: #9e9e9e;
}

.chat-user-status-away {
  background-color: #ff9800;
}


.chat-widget__message-count {
  width: 14px;
  height: 14px;
  font-weight: bold;
  color: #fff;
  font-size: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF5F5F;
  position: absolute;
  right: 12px;
  top: 15px;
}