@font-face {
  font-family: 'TitlingGothicFB';
  src: local('TitlingGothicFB'), url('/font/TitlingGothicFBComp-Med.otf');
}
@font-face {
  font-family: 'TitlingGothicFBReg';
  src: local('TitlingGothicFBReg'), url('/font/TitlingGothicFBComp-Reg.otf');
}
body {
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'TitlingGothicFB';
  cursor: default;
  -webkit-box-orient: vertical;
}
form {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  display: flex; 
  flex-direction: column;
  width: 100%;
}
img {
  width: 100%
}
.bg_new {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg_login_new{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/img/login-fensor/bg_login_mobile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.container_login_new{
  position: relative;
  width: 95%;
  height: 624px;
  background-image: url('/img/login-fensor/login_mobile.png');
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  margin: 50px 0;
  flex-wrap: wrap;
} 
.container_login_new::after{
  border-radius: 20px;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(26, 25, 25, 0.7);
}
.content_login_new{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.call_to_action_new{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  z-index: 1;
}
.content_form_new{
  display: flex;
  flex-direction: column;
}
.content_form_row_new{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}
.title_login_new{
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 0;
}
.title_login_verde_new{
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #53A304;
  margin-bottom: 0;
}
.text_login_new{
  font-weight: normal;
  font-size: .8rem;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  font-family: 'TitlingGothicFBReg';
}
.text_login_bold_new{
  font-weight: bold;
  font-size: .8rem;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'TitlingGothicFBReg';
}
.lbl_login_new{
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-bottom: 5px;
}
.input_login_new{
  width: 217px;
  height: 36px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
  padding: 0 7px;
}
.btn_login_new{
  width: 217px;
  height: 36px;
  background: #2B5F1E;
  border-radius: 5px;
  font-weight: normal;
  font-size: .9rem;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-family: 'TitlingGothicFBReg';
}
.btn_login_entrar_new{
  width: 67px;
  height: 36px;
  background: #2B5F1E;
  border-radius: 5px;
  font-weight: normal;
  font-size: .9rem;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  font-family: 'TitlingGothicFBReg';
}
.btn_login_new:hover, .btn_login_entrar_new:hover{
  background: #53A304;
}
.swiper-container_new {
  width: 90%;
  height: 100%;
}
.swiper-slide_new {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: column;
}
.linha_vertical_login_new {
  position: absolute;
  left: 50%;
  transform: rotate(90deg);
  background: #31393D;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  width: 2px;
  height: 100%;
  z-index: 1;
  top: 60%;
}
@media(min-width:300px){
  .swiper-wrapper{
    justify-content: center;
  }
  .container_login_new{
    background-image: url('/img/login-fensor/login.png');
    /* flex-wrap: nowrap; */
    height: 500px;
    width: 90%;
    display: flex;
    flex-direction: "row";
    margin: 20px 0;
  } 
  .register{
    height: 650px;
    width: 90%;
  } 
  .title_login_new{
    font-size: 1.4rem;
  }
  .title_login_verde_new{
    font-size: 1.4rem;
  }
  .input_login_new{
    width: 270px;
    height: 42px;
  }
  .btn_login_new{
    width: 270px;
    height: 42px;
    font-size: 1rem;
  }
  .btn_login_entrar_new{
    /* width: 100.53px; */
    height: 41.72px;
    font-size: 1rem;
  }
  .text_login_new{
    font-size: 1.1rem;
  }
  .text_login_bold_new{
    font-size: 1.1rem;
  }
  .swiper-container_new {
    height: 50%;
  }
  .linha_vertical_login_new{
    left: 100%;
    transform: translateY(-50%);
    height: 50%;
    top: 50%;
  }
  .bg_login_new{
    background-image: url('/img/login-fensor/bg_login.png');
  }
}
@media(min-width:370px){
  .swiper-wrapper{
    justify-content: center;
  }
  .container_login_new{
    background-image: url('/img/login-fensor/login.png');
    /* flex-wrap: nowrap; */
    height: 500px;
    width: 90%;
    display: flex;
    flex-direction: "row"
  } 
  .register{
    height: 650px;
    width: 85%;
  } 
  .title_login_new{
    font-size: 1.4rem;
  }
  .title_login_verde_new{
    font-size: 1.4rem;
  }
  .input_login_new{
    width: 270px;
    height: 42px;
  }
  .btn_login_new{
    width: 270px;
    height: 42px;
    font-size: 1rem;
  }
  .btn_login_entrar_new{
    /* width: 100.53px; */
    height: 41.72px;
    font-size: 1rem;
  }
  .text_login_new{
    font-size: 1.1rem;
  }
  .text_login_bold_new{
    font-size: 1.1rem;
  }
  .swiper-container_new {
    height: 50%;
  }
  .linha_vertical_login_new{
    left: 100%;
    transform: translateY(-50%);
    height: 50%;
    top: 50%;
  }
  .bg_login_new{
    background-image: url('/img/login-fensor/bg_login.png');
  }
}
@media(min-width:767px){
  .swiper-wrapper{
    justify-content: center;
  }
  .container_login_new{
    background-image: url('/img/login-fensor/login.png');
    /* flex-wrap: nowrap; */
    height: 500px;
    width: 90%;
    display: flex;
    flex-direction: "row"
  } 
  .register{
    height: 550px;
    /* width: 70%; */
  }
  .title_login_new{
    font-size: 1.8rem;
  }
  .title_login_verde_new{
    font-size: 1.8rem;
  }
  .input_login_new{
    width: 270px;
    height: 42px;
  }
  .btn_login_new{
    width: 270px;
    height: 42px;
    font-size: 1rem;
  }
  .btn_login_entrar_new{
    /* width: 100.53px; */
    height: 41.72px;
    font-size: 1rem;
  }
  .text_login_new{
    font-size: 1.1rem;
  }
  .text_login_bold_new{
    font-size: 1.1rem;
  }
  .swiper-container_new {
    height: 50%;
  }
  .linha_vertical_login_new{
    left: 100%;
    transform: translateY(-50%);
    height: 50%;
    top: 50%;
  }
  .bg_login_new{
    background-image: url('/img/login-fensor/bg_login.png');
  }
}
@media(min-width:880px){
  .container_login_new{
    background-image: url('/img/login-fensor/login.png');
    flex-wrap: nowrap;
    height: 500px;
    width: 65%;
  } 
  
  .register{
    height: 550px;
    width: 70%;
  }

  .title_login_new{
    font-size: 2rem;
  }
  .title_login_verde_new{
    font-size: 2rem;
  }
  .input_login_new{
    width: 270px;
    height: 42px;
  }
  .btn_login_new{
    width: 270px;
    height: 42px;
    font-size: 1rem;
  }
  .btn_login_entrar_new{
    /* width: 100.53px; */
    height: 41.72px;
    font-size: 1rem;
  }
  .text_login_new{
    font-size: 1.1rem;
  }
  .text_login_bold_new{
    font-size: 1.1rem;
  }
  .swiper-container_new {
    height: 50%;
  }
  .linha_vertical_login_new{
    left: 100%;
    transform: translateY(-50%);
    height: 50%;
    top: 50%;
  }
  .bg_login_new{
    background-image: url('/img/login-fensor/bg_login.png');
  }
}
@media(min-width:1200px){
 
  .container_login_new{
    background-image: url('/img/login-fensor/login.png');
    flex-wrap: nowrap;
    height: 500px;
    width: 60%;
  }
  .register{
    height: 550px;
    width: 70%;
  }
  .title_login_new{
    font-size: 2.4rem;
  }
  .title_login_verde_new{
    font-size: 2.4rem;
  }
  .input_login_new{
    width: 326px;
    height: 42px;
  }
  .btn_login_new{
    width: 326px;
    height: 42px;
    font-size: 1rem;
  }
  .btn_login_entrar_new{
    width: 100.53px;
    height: 41.72px;
    font-size: 1rem;
  }
  .text_login_new{
    font-size: 1.1rem;
  }
  .text_login_bold_new{
    font-size: 1.1rem;
  }
  .swiper-container_new {
    height: 50%;
  }
  .linha_vertical_login_new{
    left: 100%;
    transform: translateY(-50%);
    height: 50%;
    top: 50%;
  }
  .bg_login_new{
    background-image: url('/img/login-fensor/bg_login.png');
  }
}
@media(min-width:1700px){
  .container_login_new{
    height: 598px;
  } 
}

.text-danger:hover > svg * {
  opacity: 0.6;
}

