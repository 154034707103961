.table_expirar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin: 30px auto;
  background: var(--white);
  -webkit-box-shadow: 0 15px 30px rgba(0,0,0,.15);
  box-shadow: 0 15px 30px rgba(0,0,0,.15);
  border-radius: 5px;
  overflow-x: auto
}
.table_expirar thead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.1);
  box-shadow: 0 1px 2px rgba(0,0,0,.1)
}
.table_expirar thead tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  padding: 0 20px
}
.table_expirar thead tr th {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: normal;
  text-transform: uppercase;
  color: var(--dark-grey)
}
.table_expirar tbody {
  padding-bottom: 10px
}
.table_expirar tbody tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  padding: 0 15px
}
.table_expirar tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  padding: 10px;
  text-align: center;
  font-family: Circular;
  font-style: normal;
  font-weight: 450;
  font-size: 1rem;
  line-height: normal;
  color: var(--grey)
}
@media (min-width:1200px) {
  .table_expirar {
    margin: 70px auto;
    overflow-x: auto
  }
  .table_expirar thead {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 10px 0
  }
  .table_expirar thead tr {
    padding: 0 20px
  }
  .table_expirar thead tr th {
    width: 50%;
    padding: 10px;
    font-size: 24px;
    line-height: 30px
  }
  .table_expirar tbody {
    padding-bottom: 10px
  }
  .table_expirar tbody tr {
    width: 100%;
    padding: 0 20px
  }
  .table_expirar tbody tr td {
    width: 50%;
    padding: 10px;
    font-size: 18px;
    line-height: 23px
  }
}