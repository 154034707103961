:root {
  --white: #fff;
  --yellow: #ffe604;
  --green: #029d6f;
  --grey: #687171;
  --dark-grey: #31393d;
}

@font-face {
  font-family: Arvo;
  src: url("../../assets/fonts/arvo.otf") format("opentype");
}

@font-face {
  font-family: Arvo;
  font-weight: bold;
  src: url("../../assets/fonts/arvo-bold.woff") format("woff");
}

@font-face {
  font-family: Circular;
  src: url("../../assets/fonts/circular.woff") format("woff");

}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  cursor: default;
}

img {
  max-width: 100%;
}

.text-bold {
  font-weight: 900 !important;
}