
.card_resgate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    margin: 30px auto;
    padding: 15px;
    background: var(--white);
    -webkit-box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    border-top: 8px solid var(--yellow);
  }
  
  .card_resgate h2 {
    font-family: Arvo;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: var(--yellow);
  }
  
  .card_resgate .content_card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }
  
  .card_resgate .content_card .image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  
  .card_resgate .content_card .image img {
    display: block;
    margin: auto;
    border-radius: 30px;
  }
  
  .card_resgate .content_card .text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    padding-left: 0px;
    margin-top: 20px;
  }
  
  .card_resgate .content_card .text .dados {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%;
  }
  
  .card_resgate .content_card .text .dados h3 {
    margin-bottom: 20px;
    font-family: Circular;
    font-style: normal;
    font-weight: 900;
    font-size: 1.2rem;
    line-height: normal;
    color: var(--grey);
  }
  
  .card_resgate .content_card .text .dados p {
    margin-bottom: 10px;
    font-family: Circular;
    font-style: normal;
    font-weight: 450;
    font-size: 1rem;
    line-height: normal;
    color: var(--grey);
  }
  
  .card_resgate .content_card .text span {
    display: block;
    margin-bottom: 10px;
    font-family: Arvo;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 30px;
    color: var(--yellow);
  }
  
  .card_resgate .content_card .text .card_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 141px;
    height: 44px;
    margin: auto;
    font-family: Arvo;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--white);
    background: var(--yellow);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
  }
  
  @media (min-width: 1200px) {
    .card_resgate {
      margin: 70px auto;
      padding: 30px;
    }
    .card_resgate h2 {
      font-size: 24px;
      line-height: 30px;
    }
    .card_resgate .content_card {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      width: 80%;
      margin-top: 30px;
    }
    .card_resgate .content_card .image {
      width: 50%;
    }
    .card_resgate .content_card .text {
      width: 50%;
      padding-left: 30px;
      margin: 0;
    }
    .card_resgate .content_card .text .dados h3 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 30px;
    }
    .card_resgate .content_card .text .dados p {
      font-size: 18px;
      line-height: 23px;
    }
    .card_resgate .content_card .text span {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 30px;
    }
    .card_resgate .content_card .text .card_btn {
      width: 141px;
      height: 44px;
      margin: 0;
      font-size: 18px;
      line-height: 22px;
    }
  }
  