.mainContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  min-height: 57.5vh;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.8) , rgba(255,255,255,1));
}

@media (min-width:1200px) {
  .mainContainer {
    padding: 0 10%
  }
}
.title {
  display: block;
  margin: 15px auto;
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  color: var(--dark-grey)
}
@media (min-width:1200px) {
  .title {
    margin: 70px auto;
    font-size: 2.25rem;
    line-height: 44px;
    text-align: start
  }
}
.descript {
  font-family: Circular;
  font-style: normal;
  font-weight: 450;
  font-size: .8rem;
  line-height: normal;
  text-align: center;
  color: var(--grey)
}
@media (min-width:1200px) {
  .descript {
    font-size: 1rem;
    line-height: 23px
  }
}