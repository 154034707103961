.chat-header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  border-bottom: 4px solid #DEE4E9;
}
.chat-new {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    color: #3B3B3B;
    padding-left: 10px;
}

.new-conversation {
    font-size: 14px;
    font-weight: 500;
}

.chat-header__left {
    display: flex;
    align-items: center;
}
.chat-header__left__back {
    all: unset;
    cursor: pointer;
}
.chat-header__right {
    all: unset;
    cursor: pointer;
    transform: rotate(-90deg);
}