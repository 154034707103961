@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap);

:root {
  --main-color: transparent;
  --main-color-alfa: transparent;
  --background-image: '';
  --secondary-color: #B8872B;
}

body {
  font-family: Poppins, sans-serif;
  cursor: default;
}

img {
  width: auto;
  max-width: 100% !important;
}

textarea {
  resize: none;
  height: 104px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

input[type='file'] {
  opacity: 0;
  position: absolute;
}

.d_none {
  display: none !important;
}

/*!
**
Login
**
*/
.container_login {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-height: 100vh;
  background-image: var(--background-image);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.content_login {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 80%;
  max-width: 542px;
  margin-bottom: 15px;
  background: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
}

.login_header {
  position: relative;
  top: -1px;
  width: 100%;
  height: 10px;
  background: var(--main-color);
  box-shadow: 0 -5px 20px rgba(255, 255, 255, 0.25);
  border-radius: 10px 10px 0 0;
}

.form_login {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 80%;
}

.form_group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.form_login label {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: var(--main-color);
  margin-bottom: 5px;
}

.form_login .form_control {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 50px;
  background: #fff;
  border: 1px solid #f4f0f0;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 1rem;
  padding: 0 15px;
}

.form_footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.login_link {
  margin-bottom: 15px;
  font-size: 0.7rem;
  color: var(--main-color);
}

.btn_login {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 150px;
  height: 50px;
  background: var(--main-color);
  border-radius: 50px;
  border: 1px solid var(--main-color);
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fbfbfb;
  transition: all 0.2s linear;
}

.btn_login svg {
  fill: #fff;
  margin-right: 10px;
  transition: all 0.2s linear;
}

.btn_login:hover {
  background-color: #fff;
  color: var(--main-color);
}

.btn_login:hover svg {
  fill: var(--main-color);
}

/*!
**
Painel
**
*/
.container_painel {
  background-color: #f2f2f2;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: calc(100vh - 104px);
  overflow-y: scroll;
  padding-top: 20px;
}

.content_painel {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 100vh;
}

.header {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column !important;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start !important;
}

.logo {
  height: 30px;
  cursor: pointer;
  margin-top: 30px;
}

.options_header {
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: -15px;
}

@media (max-width: 768px) {

  header.col-12,
  .header {
    padding: 0px;
  }

  .options_header {
    flex-direction: column;
    width: 100%;
  }

  .options_header>div {
    padding: 5px 0px;
  }

  .options_header>div>a,
  .options_header>div>button {
    width: 100%;
  }

  .options_header .dropdown .drop {
    width: 100% !important;
  }

  .logo_header_right {
    margin-left: auto;
    margin-right: 35px;
  }
}

.dropdown {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  margin-bottom: 0;
}

.btn_header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 150px;
  height: 30px;
  margin-left: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  color: #fbfbfb;
  border: 0;
  background: var(--main-color);
  border-radius: 50px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.btn_header svg {
  margin-right: 5px;
  fill: #fff;
  transition: all 0.2s linear;
}

.btn_header:hover {
  background: #fff;
  color: var(--main-color);
}

.btn_header:hover svg {
  fill: var(--main-color);
}

.drop {
  display: none;
  position: relative;
  -webkit-box-orient: vertical;
  flex-direction: column;
  left: 15px;
  top: 0;
  padding: 15px;
  padding-top: 0;
  background: var(--main-color);
  border-radius: 0 0 15px 15px;
  width: 150px !important;
}

.item_drop {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 0.75rem;
  margin-top: 5px;
  color: #fff;
  width: 150px !important;
  cursor: pointer;
}

.item_drop svg {
  fill: #ffcb08 !important;
  margin-right: 5px;
}

.btn_menu_mobile {
  display: -webkit-box;
  display: flex;
  position: absolute;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  top: 30px;
  right: 0;
  background: 0 0;
  border: 0;
}

.btn_menu_mobile_close {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background: 0 0;
  border: 0;
  position: absolute;
  top: 30px;
  right: 15%;
}

.nav_atleta {
  display: none;
  position: fixed;
  -webkit-box-orient: vertical;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: var(--main-color);
  top: 0;
  left: 0;
  z-index: 10;
  margin: 0;
  opacity: 0;
}

.nav_atleta .active {
  background-color: #ffcb08;
  color: black;
}

.btn_panel {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 110px;
  height: 35px;
  margin-top: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: normal;
  color: var(--main-color);
  background: #fff;
  border-radius: 50px;
  border: 0;
  cursor: pointer;
  transition: all 0.2s linear;
}

.nav_atleta .btn_panel:hover {
  color: black;
  text-decoration: none;
  background-color: #ffcb08;
}

.content_panel {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  min-height: calc(100vh - 320px);
}

.content {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  flex: 1 1 0;
  max-width: 100%;
  min-width: 55%;
}

.card {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
  padding: 20px 15px 30px;
  background: #fff;
  border-top: 10px solid var(--main-color);
  box-shadow: 0 -5px 20px rgba(255, 255, 255, 0.25), 0 15px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.card_header {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  /* align-items: center; */
}

.card_title {
  margin-bottom: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: normal;
  text-transform: uppercase;
  color: var(--main-color);
}

.card_subtitle {
  margin-top: -5px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: normal;
  color: #000;
}

.list_card {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}

.card_list {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 100%;
  margin: 10px 5px;
  padding: 30px 15px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.artigo {
  flex-direction: column-reverse;
}

.card_list_comunicado {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.card_list_comunicado .content_card {
  -webkit-box-orient: vertical;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 15px 15px 0;
  width: 100%;
}

.card_list_turma {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  padding: 15px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.comunicado_type {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 110px;
  height: 28px;
  margin-left: auto;
  margin-bottom: 15px;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: normal;
  color: #fbfbfb;
}

.noticia {
  background: #059c08;
}

.comunicado {
  background: #e8ce05;
}

.comunicado_title {
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: normal;
  text-transform: uppercase;
  color: var(--main-color);
}

.comunicado_text {
  word-break: break-all;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: normal;
  color: #000;
}

.card_list_comunicado .btn_card {
  min-width: auto;
  width: 150px;
  height: 28px;
  font-size: 0.75rem;
}

.card_list_comunicado .btn_card svg {
  width: 14px;
  height: 14px;
}

.card_list_turma .content_card {
  margin: 0;
}

.card_list_turma .card_details {
  margin: 0;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.card_list_turma .btn_card {
  min-width: auto;
  height: 28px;
  font-size: 0.75rem;
}

.card_list_turma .btn_card svg {
  width: 14px;
  height: 14px;
}

.text_uppercase {
  text-transform: uppercase;
}

.sidebar .card_list_turma {
  width: 100%;
  padding: 15px;
  height: auto;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.sidebar .card_list_turma .card_details {
  justify-content: center;
}

.img_list_user {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
}

.img_list_user img {
  display: block;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.img_list_comunicado {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  border-radius: 10px 0 0 10px;
}

.img_list_comunicado img {
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}

.card_list .content_card {
  margin: 20px 0;
}

.card_list .card_details {
  margin-bottom: 5px;
}

.card_list .btn_card {
  min-width: auto;
  height: 28px;
  font-size: 0.75rem;
  margin: 0 auto;
}

.card_list .btn_card svg {
  width: 14px;
  height: 14px;
}

.btn_card_list {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  margin-top: 10px;
  background: var(--main-color);
  border-radius: 50px;
  border: 1px solid var(--main-color);
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fbfbfb;
  cursor: pointer;
  transition: all 0.2s linear;
}

.btn_card_list:hover {
  background-color: #fff;
  text-decoration: none;
  color: var(--main-color);
}

.sidebar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  flex: 0 1 450px;
  max-width: 100%;
}

.content_card {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0 30px;
}

.btn_card_red {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 200px;
  width: auto;
  height: 50px;
  padding: 0 10px;
  background: #841202;
  border-radius: 50px;
  border: 1px solid #841202;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fbfbfb;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s linear;
}

.btn_card_red svg {
  fill: #fff;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  transition: all 0.2s linear;
}

.btn_card_red:hover {
  background-color: #fff;
  text-decoration: none;
  color: #841202;
}

.btn_card_red:hover svg {
  fill: #841202;
}

.btn_card {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 200px;
  width: auto;
  height: 50px;
  padding: 0 10px;
  background: var(--main-color);
  border-radius: 50px;
  border: 1px solid var(--main-color);
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fbfbfb;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s linear;
}

.btn_card svg {
  fill: #fff;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  transition: all 0.2s linear;
}

.btn_card:hover {
  background-color: #fff;
  text-decoration: none;
  color: var(--main-color);
}

.btn_card:hover svg {
  fill: var(--main-color);
}

.sidebar_data {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: normal;
  color: #ffcb08;
}

.sidebar hr {
  display: block;
  width: 100%;
  height: 2px;
  margin: 30px 0;
  background-color: var(--main-color);
}

.img_school,
.img_avatar {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 100%;
}

.img_school img {
  display: block;
  margin: auto;
  height: 175px;
  width: 175px;
}

.img_avatar img {
  display: block;
  margin: auto;
  height: 80px;
  width: 80px;
}

.card_details {
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.card_details_alternative {
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.card_details_title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: normal;
  text-transform: uppercase;
  color: var(--main-color);
}

.card_details_label {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  margin-right: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: normal;
  color: var(--main-color);
  margin-bottom: 0px;
}

.card_details_text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: normal;
  color: #000;
}

.required::after {
  content: ' *';
  color: red;
  font-size: 15px;
  margin-left: 2px;
}

.form_card {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.form_group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.form_card .form_control {
  margin-top: 5px;
  padding: 0 15px;
  height: 50px;
  background: #fff;
  border: 1px solid #f4f0f0;
  box-sizing: border-box;
  border-radius: 5px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

.select {
  background: #fff url(/img/clubes/seta.png) 95.5% 50% no-repeat !important;
  color: black;
}

.form_card .btn_card {
  margin-top: 20px;
}

.footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  background: var(--main-color);
  border-radius: 10px 10px 0 0;
  box-shadow: -5px -5px 15px rgba(0, 0, 0, 0.15);
}

.nav_footer {
  display: grid;
  grid-template-areas: 'logoff logoff logoff' 'nav nav nav';
  grid-gap: 20px;
  width: 100%;
  padding: 20px 0;
}

.links_footer {
  grid-area: nav;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.footer_link {
  margin: 0 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.5625rem;
  line-height: normal;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
}

.footer_link:hover {
  text-decoration: none;
  color: #ffcb08;
}

.logout {
  grid-area: logoff;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: normal;
  color: #fff;
  transition: all 0.2s linear;
}

.logout svg {
  margin-right: 10px;
  fill: #ffcb08;
  transition: all 0.2s linear;
}

.logout:hover {
  text-decoration: none;
  color: #ffcb08;
}

.logout:hover svg {
  margin-right: 10px;
  fill: #fff;
}

.comunicacao {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  left: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 6px;
  line-height: normal;
  color: #fff;
  background-color: var(--main-color);
}

/*!
Table
*/
.table_header {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 15px;
  margin-top: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 0 0;
  z-index: 1;
}

.table_header .active,
.table_mes.active {
  color: #ffcb08;
}

.table_mes {
  font-style: normal;
  font-weight: 600;
  font-size: 0.5625rem;
  line-height: normal;
  color: var(--main-color);
  cursor: pointer;
  transition: all 0.2s linear;
}

.table_mes:hover {
  color: #ffcb08;
}

.table {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 10px 10px;
}

.table tr {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.table td,
.table th {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0 5px;
  /* width: 40px; */
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.table th {
  height: 27px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: normal;
  color: #ffcb08;
}

.table td {
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: normal;
  color: var(--main-color);
}

.table td:nth-child(1),
.table th:nth-child(1) {
  flex-grow: 1;
}

.table td:last-child,
.table th:last-child {
  border-right: 0;
}

.table td:nth-child(1) {
  -webkit-box-pack: start;
  justify-content: start;
}

.table .check::before {
  content: url(/img/clubes/check.svg);
  display: block;
  position: relative;
  height: 12px;
}

.table_day {
  font-weight: 600;
  color: #ffcb08;
}

.btn_group_card {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.btn_link_card {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}

.btn_link_card svg {
  fill: var(--main-color);
  transition: all 0.2s linear;
}

.btn_link_card:hover svg {
  fill: #ffcb08;
}

/*!
**
Modal
**
*/
.modal {
  opacity: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: var(--main-color);
}

.modal_success {
  opacity: 0;
  display: none;
  position: fixed;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  align-self: center;
  z-index: 999;
}

.modal_content {
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  flex-direction: column;
  min-height: 520px;
  width: 90%;
  max-width: 920px;
  max-height: 90vh;
  padding: 25px 15px 15px;
  background: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 -5px 20px rgba(255, 255, 255, 0.25);
  border-top: 10px solid var(--main-color);
  border-radius: 10px;
  overflow: auto;
}

.modal_content_flex {
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  flex-direction: column;
  max-width: 920px;
  max-height: 90vh;
  padding: 25px 15px 15px;
  background: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 -5px 20px rgba(255, 255, 255, 0.25);
  border-top: 10px solid var(--main-color);
  border-radius: 10px;
  overflow: auto;
}

.modal_content_success {
  display: -webkit-box;
  display: flex;
  flex: 1;
  position: relative;
  -webkit-box-orient: vertical;
  flex-direction: column;
  min-height: 300px;
  padding: 25px;
  background: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 -5px 20px rgba(255, 255, 255, 0.25);
  border-radius: 20px;
}

.percent_50 {
  /* width: 50% !important; */
}

.modal_header_success {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal_title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: normal;
  text-transform: uppercase;
  color: var(--main-color);
}

.modal_title_success {
  font-style: bold;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: normal;
  color: black;
  width: 100%;
  text-align: center;
  flex: 1
}

.modal_close {
  display: -webkit-box;
  display: flex;
  position: absolute;
  top: 15px;
  right: 30px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: normal;
  border-radius: 50%;
  color: var(--main-color);
  cursor: pointer;
}

.modal_close_success {
  width: 35px;
  height: 35px;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: normal;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: end;
}

.modal_body_success {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal_icon_success {
  background-color: rgba(39, 205, 135, 0.13);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_footer_success {
  margin-top: 15px;
}

.upload_file {
  background: var(--main-color);
  margin-bottom: 15px;
  border-radius: 10px;
  min-height: 200px;
  cursor: pointer;
}

.upload_file::before {
  content: url(/img/clubes/camera.svg);
  position: relative;
  top: calc(50% - 26px);
  left: calc(50% - 30px);
  width: 60px;
  height: 52px;
}

.form_modal {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}

.form_modal .form_control {
  margin-top: 5px;
  padding: 0 15px;
  height: 50px;
  background: #fff;
  border: 1px solid #f4f0f0;
  box-sizing: border-box;
  border-radius: 5px;
}

.form_modal .btn_card {
  margin-top: 20px;
}

.form_modal .btn_card_red {
  margin-top: 20px;
}

.modal_text {
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: normal;
}

.modal_text p {
  margin-bottom: 5px;
}

.form_group_search {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid var(--main-color);
  height: 42px;
  width: 265px;
  max-width: 100%;
  border-radius: 50px;
  padding: 5px 15px;
}

.form_group_search .form_control {
  height: 18px;
  width: 80%;
  margin-top: 0;
  border: none;
  padding: 0;
}

.form_group_search .form_control::placeholder {
  color: var(--main-color);
}

.submit_search {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 0;
  background: 0 0;
  height: 40px;
  width: 20%;
  cursor: pointer;
}

.list_modal {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}

.btn_pergunta {
  padding: 15px;
  position: relative;
  background: var(--main-color);
  border: 0;
  text-align: left;
  color: #fff;
  border-radius: 10px;
  margin-top: 15px;
}

.btn_pergunta::before {
  content: '-';
  position: absolute;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #fff;
  right: 0;
  top: calc(50% - 20px);
  font-size: 2rem;
}

.collapsed::before {
  content: '+';
  position: absolute;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #fff;
  right: 0;
  top: calc(50% - 20px);
  font-size: 2rem;
}

.btn_pergunta p {
  width: 90%;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
}

.resposta {
  padding: 15px;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.group_50 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.aluno_content {
  margin-top: 15px;
  margin-bottom: 0;
}

.aluno_content .card_details {
  justify-content: flex-start;
}

.card_list_seletiva {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  width: 45%;
  width: calc((100% / 2) - 10px);
  width: -webkit-calc((100% / 2) - 10px);
  margin: 10px 5px;
  padding-bottom: 30px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border-bottom: 10px solid;
}

.img_list_seletiva {
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  height: 130px;
  margin-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.img_list_seletiva img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  object-fit: cover;
}

.card_list_seletiva .content_card {
  margin-bottom: 15px;
}

.seletiva_result_icon {
  width: 15px;
  /* height: 15px; */
  margin-right: 5px;
}

.seletiva_result_icon svg {
  width: 15px;
  /* height: 15px; */
  fill: #000;
}

.seletiva_result {
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: normal;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: right;
}

.aprovado {
  border-color: #01820e;
}

.aprovado .seletiva_result {
  color: #01820e;
}

.aprovado .seletiva_result_icon svg {
  fill: #01820e;
}

.reprovado {
  border-color: #841202;
}

.reprovado .seletiva_result {
  color: #841202;
}

.reprovado .seletiva_result_icon svg {
  fill: #841202;
}

.card_noticia {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 0 30px;
  background: #fff;
  border-top: 10px solid var(--main-color);
  box-shadow: 0 -5px 20px rgba(255, 255, 255, 0.25), 0 15px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.img_noticia {
  width: 100%;
  height: 500px;
}

.img_noticia img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.noticia_content {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  flex-direction: column;
  padding: 15px 15px 0;
}

.comunicado_text p {
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: normal;
  color: #000;
}

.card_noticia .comunicado_type {
  position: absolute;
  top: 15px;
  right: 15px;
}

.list_select {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
}

.list_item {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  width: auto;
  margin-right: 10px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.5625rem;
  line-height: normal;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #b3b1b1;
}

.remove {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 0;
  background: 0 0;
  cursor: pointer;
}

@media (min-width: 400px) {
  .modal_content {
    padding-bottom: 150px;
  }

  .content {
    max-width: 100%;
  }

  .card_list {
    width: 100%;
    width: calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    margin: 10px 5px;
  }

  .card_title {
    font-size: 1.375rem;
  }

  .card_details_label {
    font-size: 0.75rem;
  }

  .card_details_text {
    font-size: 0.75rem;
  }

  .header {
    flex-direction: column !important;
    align-items: baseline !important;
  }

  .sidebar {
    flex: 0 1;
  }

  .table th,
  .table td {
    width: 55px;
  }

  #botoesEditarTurma {
    flex-direction: column;
  }

  .btn_card {
    margin-bottom: 5px;
  }
}

@media (min-width: 300px) {
  .card_list {
    width: 100%;
    width: calc(100% - 10px);
    width: -webkit-calc(100% - 10px);
    margin: 10px 5px;
  }

  .modal_content {
    padding-bottom: 150px;
  }

  #botoesEditarTurma {
    flex-direction: column;
  }

  .btn_card {
    margin-bottom: 5px;
  }
}

@media (min-width: 490px) {
  .card_list {
    width: 100%;
    width: calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    margin: 10px 5px;
  }

  .modal_content {
    padding-bottom: 150px;
  }

  #botoesEditarTurma {
    flex-direction: row;
  }

  .btn_card {
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  #botoesEditarTurma {
    flex-direction: row;
  }

  .btn_card {
    margin-bottom: 0px;
  }

  .header {
    -webkit-box-orient: vertical;
    flex-direction: row !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }

  .logo {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .drop {
    position: absolute;
    top: 30px;
  }

  .options_header {
    margin-top: 50px;
    margin-bottom: 0;
    margin-right: 0;
  }

  .content_panel {
    -webkit-box-orient: horizontal;
    flex-direction: row;
  }

  .sidebar {
    margin-right: 15px;
    max-width: 450px;
    flex: 0 1 450px;
  }

  .btn_menu_mobile,
  .btn_menu_mobile_close {
    display: none;
  }

  .nav_atleta {
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    height: auto;
    z-index: 0;
    margin: 50px auto 10px;
    opacity: 1;
  }

  .btn_panel {
    margin-right: 10px;
  }

  .btn_panel:last-child {
    margin-right: 0px;
  }

  .card_list {
    width: 100%;
    width: calc((100% / 3) - 10px);
    width: -webkit-calc((100% / 3) - 10px);
  }

  .btn_card {
    padding: 0 20px;
  }

  .card_list_comunicado {
    -webkit-box-orient: vertical;
    flex-direction: row;
  }

  .img_list_comunicado {
    width: 35%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px 0 0 10px;
  }

  .img_list_comunicado img {
    width: auto;
  }

  .group_50 {
    width: 45%;
    width: calc(50% - 15px);
    width: -webkit-calc(50% - 15px);
    margin: 0;
  }

  .card_list_seletiva {
    width: 33.3333%;
    width: calc((100% / 3) - 10px);
    width: -webkit-calc((100% / 3) - 10px);
  }

  .img_list_seletiva img {
    width: auto;
  }

  .table_mes {
    font-size: 1rem;
  }

  .table td,
  .table th {
    /* width: 55px; */
    padding: 0 15px;
  }

  .sidebar .card_list_turma {
    width: 100%;
    width: calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    margin: 5px;
  }

  .comunicacao {
    font-size: 0.625rem;
    padding: 10px;
  }

  .nav_footer {
    grid-template-areas: 'nav nav nav logoff';
  }

  .modal_title {
    font-size: 1.375rem;
  }

  .logout {
    margin-right: 20px;
  }

  .links_footer {
    -webkit-box-pack: start;
    justify-content: start;
  }

  .footer_link {
    margin: 0 20px;
    font-size: 0.75rem;
  }

  .collapsed::before {
    right: 30px;
  }

  .btn_pergunta::before {
    right: 30px;
  }

  .btn_pergunta p {
    font-size: 1rem;
  }

  .modal_content {
    padding: 25px 30px 15px;
  }

  .form_footer {
    -webkit-box-orient: horizontal;
    flex-direction: row;
  }

  .login_link {
    margin-bottom: 0;
  }

  .artigo {
    -webkit-box-orient: horizontal;
    flex-direction: row;
  }
}

button {
  outline: none !important;
}

.loading-card {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 10px;
  color: var(--main-color);
}

select {
  /* color: #3b3b3b; */
  text-decoration: none;
  font-weight: 400;
}

.disabled,
.form_control[disabled],
.form_control[readonly],
.MuiInputBase-root.Mui-disabled,
.MuiInputBase-root.Mui-disabled input {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: #f5f5f5 !important;
  cursor: not-allowed !important;
  opacity: 1;
  border-color: #f5f5f5 !important;
}

.react-tabs__tab {
  border-color: none !important;
  border-bottom: none !important;
}

.react-tabs {
  margin-top: 10px;
  color: #ddd;
  width: 100%;
}

.react-tabs__tab-list {
  border-bottom: none !important;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: transparent !important;
  border-bottom: 1px solid !important;
  color: var(--main-color) !important;
  border-radius: 5px 5px 0 0;
}

.badge {
  /* font-size: 90% !important; */
}

.badge-font-size-90 {
  font-size: 90% !important;
}

/* Tela de avaliação */
.card_avaliacao {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

.avaliacao_header {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.avaliacao_categoria {
  display: flex;
  align-items: center;
  width: 295px;
}

.add_categoria {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border: 0;
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.avalicao_periodo {
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}

.avaliacao_table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.avaliacao_row {
  display: flex;
  width: 100%;
  margin: 5px 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -25%;
  bottom: -50%;
  background: #fbfbfb;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #02842c;
}

input:focus+.slider {
  box-shadow: 0 0 1px #02842c;
}

input:checked+.slider:before {
  -webkit-transform: translateX(125%);
  -ms-transform: translateX(125%);
  transform: translateX(125%);
}

.slider.round {
  border-radius: 100px;
}

.slider.round:before {
  border-radius: 50%;
}

.avaliacao_input {
  display: flex;
  height: 40px;
  /* flex-grow: 1; */
  margin-left: 25px;
  margin-right: 15px;
  padding: 15px;
  background: #fbfbfb;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #000000;
}

.avaliacao_checkbox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.avaliacao_checkbox input {
  display: none;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  height: 20px;
  width: 20px;
  background: #fbfbfb;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.avaliacao_checkbox:hover input~.checkmark {
  background-color: #fbfbfb;
}

.avaliacao_checkbox input:checked~.checkmark {
  background-color: #02842c;
}

.label_avaliacao_checkbox,
.label_avaliacao_checkbox_mobile {
  position: absolute;
  top: -5px;
  font-size: 10px;
}

.label_avaliacao_checkbox_mobile {
  display: none;
}

.button_set_all {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px 0 -10px;
  background-color: transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #b2b1b1;
  border: 0;
}

.button_add_item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0 5px;
  margin-bottom: 10px;
  background-color: transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #b2b1b1;
  border: 0;
  cursor: pointer;
}

.button_add_item::after {
  content: '+';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border: 0;
  background-color: #b2b1b1;
  color: #fff;
}

.avaliacao_list {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 10px 10px;
}

.avaliacao_table_header {
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0;
  color: #000;
}

.avaliacao_table_header span {
  width: 40%;
  padding: 15px;
  border-right: 1px solid #e5e5e5;
  font-size: 16px;
}

.avaliacao_table_header span:nth-child(3) {
  width: 20%;
  text-align: center;
  border-right: 0;
}

.avaliacao_table_row {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  color: #000;
}

.avaliacao_table_row span {
  width: 40%;
  padding: 15px;
  border-right: 1px solid #e5e5e5;
}

.avaliacao_table_row span:nth-child(3) {
  width: 20%;
  text-align: center;
  border-right: 0;
}

.content_avaliacao {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.content_avaliacao_atributo {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.avaliacao_atributo_title,
.title_avaliacao_checkbox_mobile {
  margin-top: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.title_avaliacao_checkbox_mobile {
  margin: 10px auto 5px;
  display: none;
}

.avaliacao_atributo_title small {
  color: #c8102e;
}

.content_avaliacao_atributo_table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.avaliacao_atributo_descript {
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #747272;
}

.avaliacao_atributo_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 35%;
  margin: 5px 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
}

.avaliacao_atributo_row span {
  color: #747272;
}

/* Tela de avaliação da escola */

.avaliacao_escola_table_header {
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0;
  color: #000;
}

.avaliacao_escola_table_header span {
  width: 60%;
  padding: 15px;
  font-size: 16px;
}

.avaliacao_escola_table_header span:nth-child(2) {
  width: 15%;
  text-align: center;
}

.avaliacao_escola_table_header span:nth-child(3) {
  width: 25%;
  text-align: center;
}

.avaliacao_escola_table_row {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  color: #747272;
}

.avaliacao_escola_table_row span {
  width: 60%;
  padding: 15px;
  border-right: 1px solid #e5e5e5;

  display: flex;
  align-items: center;
}

.avaliacao_escola_table_row span div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.avaliacao_escola_table_row span:nth-child(2) {
  width: 15%;
  text-align: center;
  color: #c8102e;

  justify-content: center;
}

.avaliacao_escola_table_row span:nth-child(3) {
  width: 25%;
  text-align: center;
  border-right: 0;

  justify-content: center;
}

.avaliacao_escola_table_row span:nth-child(3) a {
  height: 25px;
}

.avaliacao_escola_table_row span:nth-child(3) img {
  width: 25px;
  margin: 0 7px;
  cursor: pointer;
}

.collapse_avaliacao {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  color: #747272;
}

.collapse_avaliacao div {
  display: flex;
}

.collapse_avaliacao span {
  width: 60%;
  padding: 15px;
  border-right: 1px solid #e5e5e5;

  display: flex;
  align-items: center;
}

.collapse_avaliacao div span img {
  margin-right: 10px;
  width: 12px;
}

.collapse_avaliacao span:nth-child(2) {
  width: 15%;
  text-align: center;
  color: #747272;

  justify-content: center;
}

.collapse_avaliacao span:nth-child(3) {
  width: 25%;
  text-align: center;
  border-right: 0;

  justify-content: center;
}

.collapse_avaliacao span:nth-child(3) a {
  height: 25px;
}

.collapse_avaliacao span:nth-child(3) img {
  width: 25px;
  margin: 0 7px;
  cursor: pointer;
}

.modal_erro {
  position: relative;
  display: none;
  position: fixed;
  z-index: 21;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 388px;
  height: 254px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.25);
  border-top: 10px solid #c8102e;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.modal_acoes,
.modal_acoes_interna {
  position: relative;
  display: none;
  position: fixed;
  z-index: 21;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 388px;
  height: 154px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.25);
  border-top: 10px solid var(--main-color);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.modal_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 74px;
  text-align: center;
}

.modal_container_acoes {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 74px;
  text-align: center;
}

.modal_title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--main-color);
}

.modal_text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.modal_close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--main-color);
  z-index: 1;
  cursor: pointer;
}

/* Tela de avaliação do aluno */

.content_avaliacao_atributo input {
  width: 345px;
  height: 40px;
  background: #fbfbfb;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #747272;
  max-width: 95%;
  margin-right: 14px;
}

.avaliacao_atributo_row label {
  display: flex;
  align-items: center;
  width: 194px;
  height: 40px;
  background: #fbfbfb;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 15px;
  margin-right: 14px;
}

.avaliacao_atributo_row input {
  width: 78px;
  height: 40px;
  background: #fbfbfb;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #747272;
  max-width: 100%;
}

.content_avaliacao_atributo_table textarea {
  width: 765px;
  height: 107px;
  background: #fbfbfb;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #747272;
  max-width: 100%;
}

@media (max-width: 599px) {
  .modal_content {
    padding-bottom: 150px;
  }

  .content {
    max-width: 100%;
  }

  .avaliacao_row {
    flex-direction: column;
  }

  .avaliacao_input {
    margin-right: 0;
  }

  .label_avaliacao_checkbox_mobile,
  .title_avaliacao_checkbox_mobile {
    display: block;
  }

  .avaliacao_categoria {
    width: 100%;
  }

  .avalicao_periodo {
    margin-top: 10px;
  }

  .button_set_all {
    margin: 0;
  }

  .checkmark {
    height: 18px;
    width: 18px;
  }

  .avaliacao_header .avalicao_periodo {
    display: none;
  }

  .content_avaliacao_atributo,
  .avaliacao_atributo_row {
    width: 100%;
  }

  .avaliacao_table_header span:nth-child(1),
  .avaliacao_table_row span:nth-child(1) {
    display: none;
  }

  .avaliacao_table_header span:nth-child(2),
  .avaliacao_table_row span:nth-child(2) {
    flex-grow: 1;
  }

  .avaliacao_table_header span:nth-child(3),
  .avaliacao_table_row span:nth-child(3) {
    width: 90px;
  }

  .content_avaliacao_atributo input {
    max-width: 100%;
  }

  .avaliacao_escola_table_header span,
  .avaliacao_escola_table_row span,
  .collapse_avaliacao span {
    width: 46%;
  }

  .avaliacao_escola_table_header span:nth-child(2),
  .avaliacao_escola_table_row span:nth-child(2),
  .collapse_avaliacao span:nth-child(2),
  .avaliacao_escola_table_header span:nth-child(3),
  .avaliacao_escola_table_row span:nth-child(3),
  .collapse_avaliacao span:nth-child(3) {
    width: 27%;
  }

  .avaliacao_escola_table_row span div {
    justify-content: center;
  }

  .avaliacao_escola_table_row span div img {
    margin-left: 5px;
  }

  .none_mobile {
    display: none !important;
    justify-content: center;
  }

  .none_desktop {
    display: flex !important;
    justify-content: center;
  }

  .modal_erro,
  .modal_acoes,
  .modal_acoes_interna {
    width: 300px;
  }

  .modal_container,
  .modal_container_acoes {
    padding: 25px;
  }

  .modal_container_acoes a {
    height: 25px;
    margin: 0 7px;
  }
}

@media (min-width: 600px) {
  .modal_content {
    padding-bottom: 150px;
  }

  .avaliacao_row {
    flex-direction: column;
  }

  .avaliacao_input {
    margin-right: 0;
  }

  .label_avaliacao_checkbox_mobile,
  .title_avaliacao_checkbox_mobile {
    display: block;
  }

  .avaliacao_categoria {
    width: 100%;
  }

  .avalicao_periodo {
    margin-top: 10px;
  }

  .button_set_all {
    margin: 0;
  }

  .checkmark {
    height: 18px;
    width: 18px;
  }

  .avaliacao_header .avalicao_periodo {
    display: none;
  }

  .content_avaliacao_atributo,
  .avaliacao_atributo_row {
    width: 100%;
  }

  .avaliacao_escola_table_header span,
  .avaliacao_escola_table_row span,
  .collapse_avaliacao span {
    width: 58%;
  }

  .avaliacao_escola_table_header span:nth-child(2),
  .avaliacao_escola_table_row span:nth-child(2),
  .collapse_avaliacao span:nth-child(2) {
    width: 17%;
  }

  .avaliacao_escola_table_row span:nth-child(3) img,
  .collapse_avaliacao span:nth-child(3) img {
    margin: 0 3px;
  }

  .none_mobile {
    display: flex !important;
    justify-content: center !important;
  }

  .none_desktop {
    display: none !important;
    justify-content: center !important;
  }
}

@media (min-width: 1200px) {
  .avaliacao_row {
    flex-direction: row;
  }

  .avaliacao_input {
    margin-right: 15px;
  }

  .label_avaliacao_checkbox_mobile,
  .title_avaliacao_checkbox_mobile {
    display: none;
  }

  .avaliacao_categoria {
    width: 295px;
  }

  .avalicao_periodo {
    margin-top: 0;
  }

  .button_set_all {
    margin: 0 15px 0 -10px;
  }

  .checkmark {
    height: 20px;
    width: 20px;
  }

  .avaliacao_header .avalicao_periodo {
    display: flex;
  }

  .content_avaliacao_atributo {
    width: 50%;
  }

  .avaliacao_atributo_row {
    width: 34%;
  }

  .avaliacao_escola_table_header span,
  .avaliacao_escola_table_row span,
  .collapse_avaliacao span {
    width: 60%;
  }

  .avaliacao_escola_table_header span:nth-child(2),
  .avaliacao_escola_table_row span:nth-child(2),
  .collapse_avaliacao span:nth-child(2) {
    width: 15%;
  }

  .avaliacao_escola_table_row span:nth-child(3) img,
  .collapse_avaliacao span:nth-child(3) img {
    margin: 0 7px;
  }

  .modal_erro,
  .modal_acoes,
  .modal_acoes_interna {
    transform: translateY(-50%);
  }
}

.color-default {
  color: var(--main-color) !important;
}

.background-color-default {
  background-color: var(--main-color) !important;
}

.border-color-default {
  border-color: var(--main-color) !important;
}

.btn_card_outline {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 100px;
  width: auto;
  height: 40px;
  padding: 0 10px;
  background: #fff;
  border-radius: 50px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: gray;
  border: 1px solid gray;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s linear;
}

.btn_card_outline svg {
  fill: #fff;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  transition: all 0.2s linear;
}

.btn_card_outline:hover {
  background: gray;
  text-decoration: none;
  color: #fff;
}

.table_reports {
  margin-top: 20px;
}

.table_reports thead {
  background: #ddd;
  height: 40px;
}

.table_reports thead tr {
  font-size: 12px;
}

.table_reports thead tr th {
  font-size: 12px;
  padding-left: 10px;
}

.table_reports tbody tr {
  height: 30px;
}

.table_reports tbody tr td {
  font-size: 12px;
  padding-left: 10px;
}

.link-normal {
  color: black;
}

.link-normal:hover {
  color: #11bfe3;
}

.marker::before {
  content: '\2022';
  margin-right: 5px;
  font-weight: bold;
}

.sidebar-multiple {
  display: flex;
  flex: 1px;
  flex-direction: row;
  gap: 10px;
  margin-right: 0px;
}

@media (max-width: 580px) {
  .sidebar-multiple {
    flex-direction: column;
  }
}

.list-card-slider {
  max-height: 360px;
  overflow: hidden;
  flex-wrap: initial;
}

.container_painel header .header {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.clickable {
  cursor: pointer;
}

.badge-simple-red {
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  background: #dc3545;
  color: white;
  font-size: 14;
  margin-right: 10;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

.dashboard-columns {
  display: flex;
  flex: 1px 1;
  grid-gap: 10px;
  gap: 10px;
  margin-right: 0px;
}

@media (max-width: 600px) {
  .dashboard-container {
    flex-direction: column;
  }

  .dashboard-columns {
    flex-direction: column;
  }
}

@media (min-width: 600px) {
  .dashboard-container {
    flex-direction: row;
  }

  .dashboard-columns {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .dashboard-container {
    flex-direction: row;
  }

  .dashboard-columns {
    flex-direction: row;
  }
}

.form_modal .form_control.checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;

  height: fit-content;
  width: fit-content;

  background: #fff;
  border: none;
  box-sizing: border-box;
  font-size: 0.8rem;

  display: flex;
  align-items: center;
}

.form_modal .form_control.checkbox input[type='checkbox'] {
  margin-right: 5px;
}

table th.col-min,
table td.col-min {
  width: 10px;
  padding: 0 10px;
}

@media (min-width: 400px) and (max-width: 992px) {
  .btn_header.btn_header_resize {
    width: auto;
    padding: 0 15px;
  }
}

.input-rounded {
  overflow: hidden;
  border-radius: 50px;
  border: 1px solid #f4f0f0;
  margin: 0 15px;
  padding: 0 15px;
  position: relative;
}

.input-rounded select {
  padding: 0px;
  text-align: center;
  line-height: normal;
}

.input-rounded input {
  text-align: center;
  line-height: normal;
}

.input-rounded input[type='date']::-webkit-calendar-picker-indicator {
  padding: 0px;
  margin: 0px;
}

.dashboard-top-card {
  width: 100%;
  display: flex;
}

@media (max-width: 766px) {
  .dashboard-top-card {
    display: block;
  }

  .dashboard-top-card .card {
    /* min-width: 565px; */
  }
}

.indicators-wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}

.indicators-inner-wrapper {
  flex: 1 1 555px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}

.indicators-column {
  flex: 1 1 277.5px;
  height: fit-content;
}

.indicators-column hr {
  display: block;
  width: 100%;
  height: 2px;
  margin: 30px 0;
  background-color: var(--main-color);
}

.flex-justify-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

table.table-highlight-hover tbody tr:hover {
  --table-highlight-hover-background-color: #92bce03b;
  background-color: var(--table-highlight-hover-background-color) !important;
}

table.table.d-table.table-highlight-hover tbody tr:hover td {
  color: #000 !important;
}

table.table.d-table tbody tr.static-highlight-tr {
  --static-highlight-tr-background-color: #fff;
  background-color: var(--static-highlight-tr-background-color);
}

.btn_card_grey {
  color: #fff;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn_card_grey svg {
  fill: #fff;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  transition: all 0.2s linear;
}

.btn_card_grey:hover {
  background-color: #fff;
  text-decoration: none;
  color: #c8c8c8;
}

.btn_card_grey:hover svg {
  fill: #c8c8c8;
}

.scrollbar-rounded::-webkit-scrollbar-thumb {
  --scrollbar-rounded-color: var(--main-color);
  background-color: var(--scrollbar-rounded-color);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.scrollbar-rounded::-webkit-scrollbar {
  width: 16px;
}

.scrollbar-rounded::-webkit-scrollbar-track:vertical {
  background-color: var(--scrollbar-rounded-background, #fff);
  border-radius: 0px 8px 8px 0px;
}

.scrollbar-rounded::-webkit-scrollbar-track:horizontal {
  background-color: var(--scrollbar-rounded-background, #fff);
  border-radius: 0px 0px 8px 8px;
}

.pseudo-table {
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
  background: #ffffff;

  width: 100%;
  max-height: 80vh;
}

.pseudo-table>.pseudo-table-wrapper {
  overflow: hidden;
  border-radius: 8px;

  position: relative;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll {
  overflow: auto;
  display: flex;
  max-height: 80vh;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list {
  flex: 1;
  height: 100%;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>div>div {
  padding: 15px;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>.pseudo-table-header {
  display: flex;

  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
  clip-path: inset(0px 0px -10px 0px);

  z-index: 1;
  position: sticky;
  top: 0;

  /* background: white; */
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>.pseudo-table-header>div {
  font-size: 16px;
  border-right: 1px solid #e5e5e5;

  background: white;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>.pseudo-table-header>.pseudo-table-header-label {
  flex: 1;
  min-width: 300px;

  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
  clip-path: inset(0px -10px 0px 0px);

  position: sticky;
  left: 0;

  /* background: white; */
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>.pseudo-table-header>.pseudo-table-header-action {
  width: 200px;
  min-width: 200px;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>.pseudo-table-row {
  display: flex;

  border-bottom: 1px solid #e5e5e5;
  color: #747272;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>.pseudo-table-row>div {
  border-right: 1px solid #e5e5e5;

  display: flex;
  align-items: center;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>.pseudo-table-row>.pseudo-table-row-label {
  flex: 1;
  min-width: 300px;

  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
  clip-path: inset(0px -10px 0px 0px);

  position: sticky;
  left: 0;

  background: white;

  justify-content: space-between;
}

.pseudo-table>.pseudo-table-wrapper>.pseudo-table-scroll>.pseudo-table-list>.pseudo-table-row>.pseudo-table-row-action {
  width: 200px;
  min-width: 200px;
}

input[type='checkbox'] {
  accent-color: #9b59b6;
}

.input-pb {
  padding-bottom: 15px;
}

.react-calendar {
  width: 100%;
  border: none;
}

.react-calendar__navigation__label {
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--main-color);
  text-transform: uppercase;
}

.shadow-y-inner {
  position: relative;
}

.shadow-y-inner::before,
.shadow-y-inner::after {
  position: absolute;
  left: 0;
  right: 0;
  content: '';
  height: 30px;
  border-radius: 10px;
}

.shadow-y-inner::before {
  /* box-shadow: 30px 0px 40px 0px rgb(0 0 0 / 10%); */
  box-shadow: inset 0 10px 8px -8px #00000026;
  top: 0;
}

.shadow-y-inner::after {
  /* box-shadow: 0px 30px 40px 0px rgb(0 0 0 / 10%); */
  box-shadow: inset 0 -10px 8px -8px #00000026;
  bottom: 0;
}

.bg-info-light {
  background-color: #17a2b84f;
}

.form_group .MuiAutocomplete-root {
  margin-top: 5px;
}

.form_group .MuiInputBase-input {
  height: 10px;
}

.form_group .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #f4f0f0;
}

.form_group .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #f4f0f0;
}

.select-height {
  height: var(--select-height) !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  border-radius: 0 8px 8px 0;
}

.custom-scrollbar-small-height::-webkit-scrollbar {
  height: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border: 3px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #acacac;
  background-clip: padding-box;
}

.custom-scrollbar-bg-white.custom-scrollbar::-webkit-scrollbar {
  background-color: white;
}

.c-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--main-color);
}

.c-list-item:hover {
  color: rgb(0 0 0 / 35%);
}

.bagde-title {
  font-size: 0.75rem;
  margin-right: 5px;
}

.badge-items {
  border: 2px solid #f2f4f6;
  border-radius: 5px;
  background-color: #B8872B;
  padding: 3px 15px;
  font-size: 0.5rem;
  margin-left: -0.5rem;
  z-index: 1;
  color: white;
}

.badge-items-button {
  padding: 2.5px;
  border: 2px solid #f2f4f6;
  border-radius: 100%;
  background-color: #E85252;
  font-size: 0.250rem;
  font-weight: bold;
  color: #f2f4f6;
  z-index: 2;
}

.rectangle-container {
  height: 70px;
  flex-shrink: 0;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  margin-top: 15px;
  border-radius: 14px;
  border: 1.744px solid #dee4e9;
  background: #fff;
}

.btn-secondary-color {
  border-radius: 0.5rem;
  border: 1px solid var(--main-color);
  background: var(--secondary-color);
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: white;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-main-color {
  border-radius: 0.5rem;
  border: 1px solid var(--secondary-color);
  background-color: #FFF;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-disabled {
  border-radius: 0.5rem;
  border: 0;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: 500;
  color: #8c8c8c;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-shadow {
  position: absolute;
  top: 26px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.dropdown-shadow div:hover {
  background-color: #E3F7FF;
}

.dropdown-shadow div:last-child:hover {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.badge-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7.5px 15px;
}

.bage-button-active {
  border-radius: 8;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.bage-button-inactive {
  border-radius: 8;
  border-bottom-left-radius: 8;
  border-bottom-right-radius: 8;
}

.planning-header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid var(--secondary-color);
}

.main-pill {
  background-color: var(--secondary-color);
  border-radius: 6.5px;
  height: 13px;
  width: 13px;
}

.disable-pill {
  background-color: #8c8c8c;
  border-radius: 6.5px;
  height: 13px;
  width: 13px;
}

.autocomplete-input {
  position: absolute;
  top: 55px;
  left: 17px;
  width: 96%;
  border: 1px solid #f3f3f3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
  background: white;
  z-index: 9999;
}

.autocomplete-input-list {
  padding: 5px;
}

.autocomplete-input-list:hover {
  background-color: #E3F7FF;
}

.resume>ul {
  list-style: none;
}

.resume>ul>li {
  display: flex;
  align-items: center;
}

.resume>ul>li::before {
  content: "\2022";
  color: var(--secondary-color);
  display: inline-block;
  width: 1rem;
  font-weight: bold;
  font-size: 18px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: var(--secondary-color);
  color: black;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: var(--secondary-color);
  opacity: 0.5;
  border-radius: 6px;
  font-weight: bold;
  color: black;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--secondary-color);
}

.react-calendar__tile--range {
  background: var(--secondary-color);
  opacity: 0.3;
  color: black;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: var(--secondary-color);
  color: black;
  opacity: 1;
}

.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: var(--secondary-color);
  color: black;
  opacity: 1;
}

.grid-microcycle {
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  padding: 5px;
}

.secondary-color {
  color: var(--secondary-color);
}

.modal-general {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-general-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 80%;
  max-width: 500px;
}

.modal-general-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}