/* general content */
.menuPage {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;
}

/* menu content */
.sidebar-menu {
    max-width: 320px;
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: var(--main-color);
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    transition: width 0.25s ease-in-out;
}
.menu-label,
.menu-item {
    text-decoration: none;
    font-size: 14px;
}

.sidebar-menu.collapsed {
    width: 80px;
    background-color: var(--main-color);
    padding: 0px 8px 0px 8px;
    .menu-label {
        display: none;
    }
    .menu-icon {
        display: none;
    }
    .menu-item {
        justify-content: center;
    }
    .menu-rol-icon {
        margin-right: 0;
    }
}

.menu-body {
    max-width: 294px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.menu-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.logo-new-sidebar {
    width: 80%;
    max-width: 250px;
    height: auto;
    margin: 40px 0;
    transition: opacity 0.5s ease-in-out;
}

/* menu itens */
.sidebar-menu ul {
    list-style: none;
    padding: 0;
    cursor: pointer;
}

.sidebar-menu li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 17px;
    color: #fff;
}

.menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 20px;
    padding-left: 20px;
    color: #fff;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
}

.menu-item:hover,
.menu-item.active {
    background-color: #fff;
    color: var(--main-color);
    text-decoration: none;
}
.menu-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
}

.menu-rol-icon {
    margin-right: 20px;
    transition: transform 0.2s ease-in-out;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* submenu itens */

.submenu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    font-size: 14px;
}

.submenu-item:first-child {
    text-decoration: none;
}

.sidebar-menu .submenu-closed {
    display: none;
}

.sidebar-menu .submenu-open {
    display: block;
}

.submenu-label {
    color: #fff;
}

.submenu-icon {
    padding-left: 20px;
    color: #fff;
    margin-left: 35px;
    margin-right: 10px;
    font-size: 5px;
}

/* page content */
.page-content {
    background-color: #f0f0f0;
    color: black;
    display: flex;
    max-width: 100vw;
    flex-direction: column;
    width: 100%;
    transition: margin-left 0.3s;
}

/* topbar */
.header-topbar {
    max-height: 104px;
    height: 100%;
    margin: 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 4px solid #dee4e9;
}

.header-section-left {
    font-size: 18px;
    font-weight: 700;
}

.header-section-right {
}
.header-section-fixed {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.header-section-right-top {
    text-align: right;
    margin-right: 15px;
}

.header-school {
    font-size: 16px;
    font-weight: 700;
}

@media (max-width: 575px) {
    .header-section-left {
        font-size: 14px;
    }
    .header-school {
        font-size: 14px;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
        text-wrap: nowrap;
    }
    .header-student {
        font-size: 12px;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
        text-wrap: nowrap;
        justify-content: flex-start;
    }
    .header-student span {
        display: none;
    }
}

.header-student {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
}

.header-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    color: #f5f5f5;
    background-color: #00a4ea;
    font-weight: 500;
    font-size: 18px;
}

@media (min-width: 1025px) {
    .sidebar-menu {
        display: flex;
    }
    .hidden-menu {
        display: none;
    }
}

@media (max-width: 1024px) {
    .sidebar-menu {
        display: none;
    }

    .hidden-menu {
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: var(--main-color);
    }
    .hidden-icon {
        width: 120px;
    }
    .hidden-menu-left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-left: 30px;
    }
    .hidden-menu-right {
        margin-right: 30px;
    }
    .hidden-menu-button,
    .hidden-menu-button svg {
        width: 100%;
        height: 100%;
        border: none;
        background-color: var(--main-color);
        color: #fff;
    }
    .hidden-menu-list {
        display: flex;
        position: absolute;
        overflow-y: auto;
        max-height: 100vh;
    }

    /* menu mobile */
    .menu-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 2;
        position: absolute;
        height: 100vh;
        width: 100vw;
        max-height: 100vh;
        overflow-y: auto;
        background-color: white;
        color: var(--main-color);
        padding-top: 16px;
        padding-left: 30px;
    }

    .menu-mobile-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px 0;
        width: 100%;
        max-width: 100vw;
        border-bottom: 1px solid #f5f5f5;
    }
    .menu-mobile-item a {
        display: flex;
        color: var(--main-color);
        text-decoration: none;
    }
    .menu-mobile-label {
        display: flex;
        cursor: pointer;
        font-size: 18px;
    }
    .menu-mobile-label.active {
        background-color: #fff;
    }
    .menu-mobile-icon {
        margin-right: 10px;
    }
    .submenu-mobile {
        list-style: none;
        margin: 10px 20px 0px;
        display: flex;
        flex-wrap: wrap;
    }
    .submenu-mobile a {
        color: var(--main-color);
        margin: 0px 5px;
        white-space: nowrap;
        background-color: #f5f5f5;
        padding: 3px 6px;
        border-radius: 5px;
    }
    .submenu-mobile-item {
        text-decoration: none;
        margin-bottom: 10px;
    }
    .submenu-mobile-item a {
        display: flex;
        flex-direction: row;
    }
    .sidebar-mobile-icon {
        font-size: 20px;
    }
}

/* menu profile */

.profile-menu-dropdown {
    width: 250px;
    position: absolute;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #dee4e9;
    border-radius: 5px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.menu-toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff; /* Cor do ícone */
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; /* Espaçamento entre o botão e o logo */
}

.menu-toggle-button:focus {
    outline: none;
}

.logout-button {
    width: 100%;
    height: 50px;
    background-color: #f5f5f5;
    color: var(--main-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
}
.logout-button:hover {
    background-color: #dee4e9;
}

.logout-button svg {
    margin: 10px;
}
